<template>
  <div class="bgcard">
    <div>
      <div class="headerMsg">
        <div class="addbtn">
          <button @click="addadmin">{{ $t("lang.Addadministrator") }}</button>
        </div>
        <div class="searchlist">
          <div class="select_b" style="margin-right: 0.2rem">
            <span style="color: #bbbbbb">{{ $t("lang.role") }}：</span>
            <el-select
              style="height: 0.4rem; width: 1.5rem"
              v-model="value1"
              :placeholder="$t('lang.Please')"
              popper-class="select_a"
              @change="role"
            >
              <el-option :label="$t('lang.whole')" value=""></el-option>
              <el-option
                v-for="item in stateoptions_a"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select_b" style="margin-right: 0.2rem">
            <span style="color: #bbbbbb">{{ $t("lang.state") }}：</span>
            <el-select
              style="height: 0.4rem; width: 1.5rem"
              v-model="value2"
              :placeholder="$t('lang.Please')"
              popper-class="select_a"
              @change="stauts"
            >
              <el-option :label="$t('lang.whole')" value=""></el-option>
              <el-option :label="$t('lang.normal')" :value="1"></el-option>
              <el-option :label="$t('lang.nonormal')" :value="2"></el-option>
            </el-select>
          </div>
          <div class="query">
            <div class="inputname">
              <input
                v-model="value3"
                :placeholder="$t('lang.Pleaseentertheadministratorname')"
              />
            </div>
            <div class="searchbtn">
              <button @click="search">
                <p>{{ $t("lang.search") }}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="serverfroms">
        <el-table :data="tableData.list" style="width: 100%">
          <template slot="empty">
            <div class="noCart">{{ $t("lang.tableNull") }}</div>
          </template>
          <el-table-column
            prop="name"
            :label="$t('lang.fullname')"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="email"
            :label="$t('lang.mailbox')"
            align="center"
          >
          </el-table-column>
          <el-table-column :label="$t('lang.role')" align="center">
						<template #default="scope">
							<span>{{scope.row.roleName |text}}</span>
						</template>
          </el-table-column>
          <el-table-column :label="$t('lang.state')" align="center">
            <template #default="scope">
              <span v-if="scope.row.status == 1">{{ $t("lang.normal") }}</span>
              <span v-else>{{ $t("lang.nonormal") }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lang.Isitonline')" align="center">
            <template #default="scope">
              <span v-if="scope.row.state == 1">{{ $t("lang.onLine") }}</span>
              <span v-else>{{ $t("lang.offline") }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lang.operation')" align="center">
            <template #default="scope">
              <div class="bottom_tabes" v-if="scope.row.name != '超级管理员'">
                <el-tooltip effect="dark" :content='$t("lang.edit")' placement="left">
                  <div class="bottom_imgs bottom_compile"
                    @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                    @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                    @click="handleEdit(scope.row)"
                  >
                    <img
                      class="imgOne"
                      v-if="scope.row.flag1 === 0"
                      src="@/assets/imgs/bottom/bianji.png"
                      alt=""
                    />
                    <img
                      class="imgTwo"
                      v-if="scope.row.flag1 === 1"
                      src="@/assets/imgs/bottom/bianji2.png"
                      alt=""
                    />
                  </div>
                </el-tooltip>
                <div class="btn_dise">|</div>
                <el-tooltip
                  effect="dark"
                  :content="$t('lang.deletes')"
                  placement="left"
                >
                  <div
                    class="bottom_imgs bottom_delete"
                    @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                    @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                    @click="handleDelete(scope.row)"
                  >
                    <img
                      class="imgOne"
                      v-if="scope.row.flag2 === 0"
                      src="@/assets/imgs/bottom/shanchu.png"
                      alt=""
                    />
                    <img
                      class="imgTwo"
                      v-if="scope.row.flag2 === 1"
                      src="@/assets/imgs/bottom/shanchu2.png"
                      alt=""
                    />
                  </div>
                </el-tooltip>
              </div>
              <!-- <el-button
                v-if="scope.row.name != '超级管理员'"
                class="buttons"
                size="small"
                style="background-color: #307b37"
                @click="handleEdit(scope.row)"
                >{{ $t("lang.edit") }}</el-button
              >
              <el-button
                v-if="scope.row.name != '超级管理员'"
                class="buttons"
                size="small"
                style="background-color: #1061c5"
                @click="handleDelete(scope.row)"
                >{{ $t("lang.deletes") }}</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; align-items: center" v-if="tableData.list">
          <div class="allNum">
            {{ $t("lang.total") }}: {{ tableData.total }}
          </div>
          <el-pagination
            background
            v-if="tableData.list"
            layout="prev, pager, next"
            :total="tableData.total"
            @current-change="handlePageCurrentChange"
            :prev-text="$t('lang.previouspage')"
            :next-text="$t('lang.nextpage')"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加管理员模态框 -->
    <div class="addAmin">
      <el-dialog
        :title="$t('lang.Addadministrator')"
        v-model="addval"
        width="6.3rem"
        :visible.sync="addval"
        :close-on-click-modal="false"
      >
        <div>
          <div class="aminMsg">
            <p>{{ $t("lang.role") }}:</p>
            <el-select
              popper-class="aminoption"
              v-model="addadm.roleId"
              :placeholder="$t('lang.Please')"
            >
              <el-option
                v-for="item in stateoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="aminMsg">
            <p>{{ $t("lang.fullname") }}:</p>
            <input
              v-model="addadm.name"
              :placeholder="$t('lang.Pleaseinputthecontent')"
            />
          </div>
          
          <div class="aminMsg">
            <p>{{ $t("lang.mailbox") }}:</p>
            <input
              v-model="addadm.email"
              :placeholder="$t('lang.Pleaseinputemail')"
            />
          </div>
          <div class="aminMsg">
            <p>{{ $t("lang.defaultPassword") }}:</p>
            <input v-model="modals" :disabled="true" />
          </div>
          <!-- <div class="amins aminMsg_a">
            <p class="amins_s">{{ $t("lang.VerificationCode") }}:</p>
            <input
              width="60%"
              v-model="addadm.code"
              :placeholder="$t('lang.Pleaseentertheverificationcode')"
            />
            <div class="sendmsgs" @click="sendmsg">
              <p v-show="msgtime">{{ $t("lang.Sendverificationcode") }}</p>
              <p v-show="!msgtime">{{ count }}S</p>
            </div>
          </div> -->
        </div>
        <span class="dialog-footer">
          <button @click="addval = false">{{ $t("lang.cancel") }}</button>
          <el-button size="small" @click="addAmin">{{
            $t("lang.determine")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 编辑管理员模态框 -->
    <div class="editadmin">
      <el-dialog
        :title="$t('lang.Editoradministrator')"
        v-model="writeval"
        width="6.4rem"
        :visible.sync="writeval"
      >
        <div>
          <div class="aminMsg">
            <p>{{ $t("lang.role") }}:</p>
            <el-select
              popper-class="aminoption"
              v-model="editadm.roleId"
              :placeholder="$t('lang.Please')"
              :close-on-click-modal="false"
            >
              <el-option
                v-for="item in stateoptions_a"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="aminMsg">
            <p>{{ $t("lang.fullname") }}:</p>
            <input
              v-model="editadm.name"
              :placeholder="$t('lang.Pleaseinputthecontent')"
            />
          </div>
          <div class="aminMsg">
            <p>{{ $t("lang.mailbox") }}:</p>
            <input
              v-model="editadm.email"
              :placeholder="$t('lang.Pleaseinputemail')"
            />
          </div>
          <!-- <div class="aminMsg">
            <p>{{ $t("lang.defaultPassword") }}:</p>
            <input v-model="modals" :disabled="true" />
          </div> -->
          <div class="aminMsg">
            <p>{{ $t("lang.state") }}:</p>
            <el-select popper-class="aminoption" v-model="editadm.status">
              <el-option :label="$t('lang.normal')" :value="1"></el-option>
              <el-option :label="$t('lang.nonormal')" :value="2"></el-option>
            </el-select>
          </div>
        </div>
        <span class="dialog-footer">
          <button @click="writeval = false">{{ $t("lang.cancel") }}</button>
          <button @click="handleDeter()">{{ $t("lang.determine") }}</button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as cloud from "@/api/user.js";
import * as roleList from "@/api/roleList.js";
import * as RegExp from "@/ui-utils/RegExp.js";
import * as userReg from "@/api/apis.js";
export default {
  data() {
    return {
      seenOnetext: this.$t("lang.binding"),
      seenTwotext: this.$t("lang.Unbundling"),
      seenFreetext: this.$t("lang.details"),
      seenOne: true,
      seenTwo: true,
      seenThree: true,
      seenFree: true,
      seenFive: true,

      modals:"123456", //默认密码
      value1: "",
      value2: "",
      value3: "",
      parsm: {
        page: 1,
        size: 10,
        roleId: "",
        status: "",
        nameOrEmail: "",
      },
      tableData: [],
      addval: false,
      writeval: false,
      stateoptions: [], //角色
      stateoptions_a: [],
      addadm: {
        roleId: "",
        name: "",
        email: "",
      },
      msgtime: true,
      count: "",
      timer: null,
      editadm: {
        roleId: "",
        name: "",
        email: "",
        id: "",
        status: "",
      },
			langs:false
    };
  },
	created() {
		if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
			this.langs=true
		}else{
			this.langs=false
		}
	},
	filters:{
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val
				if(zhon=='zhong'){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
				text=sk
			}
			return text
		}
	},
  mounted() {
    this.getList();
    this.getrolelist();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag2 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag2 = 0;
          }
        }
      }
    },

    //角色筛选
    role(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.roleId = this.value1;
      this.getList();
    },
    //状态筛选
    stauts() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.status = this.value2;
      this.getList();
    },
    //名称筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.nameOrEmail = this.value3;
      this.getList();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取管理员列表
    async getList() {
      let res = await cloud.list(this.parsm);

      if (res) {
        console.log(res, "6666666");
        res.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
        });
        this.tableData = res;
      }
    },
    //获取角色
    async getrolelist() {
      let res = await roleList.list();
      //console.log(res)
      this.stateoptions = res;
      this.stateoptions_a = JSON.parse(JSON.stringify(res));
			if(this.stateoptions_a){
				this.stateoptions_a.forEach((item)=>{
					let sk=item.name
					if(this.langs){
						let wq=sk.split('-')
						sk=wq[0]
					}else{
						let wq=sk.split('-')
						sk=wq[1]
						if(wq.length==1){
							sk=wq[0]
						}
					}
					item.name=sk
				})
			}
      this.stateoptions.forEach((item) => {
        if (item.name == "超级管理员") {
          this.stateoptions.splice(item, 1);
        }
				let sk=item.name
				if(this.langs){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
				item.name=sk
      });
    },
    //编辑
    handleEdit(row) {
      this.writeval = true;
      this.editadm.roleId = row.roleId;
      this.editadm.name = row.name;
      this.editadm.email = row.email;
      this.editadm.status = row.status;
      this.editadm.id = row.id;
    },
    //删除
    handleDelete(row) {
      this.$confirm(
        this.$t("lang.Thisoperationdeletetheadministrator"),
        this.$t("lang.Tips"),
        {
          confirmButtonText: this.$t("lang.determine"),
          cancelButtonText: this.$t("lang.cancel"),
          type: "warning",
        }
      ).then(() => {
        cloud.deletes({ id: row.id }).then((res) => {
          this.$message.success(this.$t("lang.deletessuccess"));
          this.getList();
        });
      });
    },
    //打开添加模态框
    addadmin() {
      this.addadm.roleId = "";
      this.addadm.name = "";
      this.addadm.email = "";
      this.addval = true;
    },
    //发送验证码
    sendmsg() {
      if (!RegExp.email.test(this.addadm.email)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrectemail"));
        return false;
      }
      if (!this.timer) {
        userReg.sendMail({ email: this.addadm.email, code: 1 }).then((res) => {
          this.$message.success(this.$t("lang.Sentsuccessfully"));
          this.getCode();
        });
      }
    },
    //倒计时
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.msgtime = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.msgtime = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //添加管理员
    addAmin() {
      //console.log(this.addadm)
      if (
        this.addadm.roleId == "" ||
        this.addadm.name == "" ||
        this.addadm.email == ""
      ) {
        this.$message.error(this.$t("lang.Pleasecompletetheinformation"));
        return false;
      }
      if (!RegExp.email.test(this.addadm.email)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrectemail"));
        return false;
      }
      cloud.add(this.addadm).then((res) => {
        this.$message.success(this.$t("lang.addsuccess"));
        this.addval = false;
        this.getList();
      });
    },
    //编辑管理员
    handleDeter() {
      if (
        this.editadm.roleId == "" ||
        this.editadm.name == "" ||
        this.editadm.email == "" ||
        this.editadm.status == ""
      ) {
        this.$message.error(this.$t("lang.Pleasecompletetheinformation"));
        return false;
      }
      if (!RegExp.email.test(this.editadm.email)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrectemail"));
        return false;
      }
      console.log(this.editadm);
      //cloud.editAolse(aoles);
      cloud.edit(this.editadm).then((res) => {
        this.$message.success(this.$t("lang.Editorsuccess"));
        this.writeval = false;
        this.getList();
      });
    },
  },
};
</script>
<style>
.el-input {
  font-size: 0.16rem !important;
}
</style>
<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;
  .addbtn {
    button {
      height: 0.5rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.aminMsg {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  p {
    width: 1.35rem;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    white-space: nowrap;
  }
}
.amins {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.2rem;
  .amins_s {
    width: 1.55rem;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    white-space: nowrap;
  }
}
/* 验证码发送 */
.sendmsgs {
  width: 1.16rem;
  height: 0.5rem;
  margin-left: 0.1rem;
  background: #3e3f3e;
  border-radius: 0.06rem;
  cursor: pointer;
  p {
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.5rem;
  }
}
.addAmin,
.editadmin {
  .dialog-footer {
    button {
      width: 0.92rem;
      height: 0.46rem;
      border: none;
      outline-style: none;
      background: #fff;
      border: 0.01rem solid #438749;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      &:first-of-type {
        margin-right: 0.36rem;
        color: #438749;
      }
      &:last-of-type {
        background: #438749;
        color: #ffffff;
      }
    }
  }
}
.aminMsg_a input {
  width: 60%;
}
</style>
