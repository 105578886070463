import request from '../utils/request.js'

//获取管理员列表
export function list(data) {
	return request({
		url: `/admin/list`,
		method: 'get',
		params:data
	})
}
//添加管理员
export function add(data) {
	return request({
		url: `/admin/add?code=${data.code}`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//编辑管理员
export function edit(data) {
	return request({
		url: `/admin/modify`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//删除管理员
export function deletes(data) {
	return request({
		url: `/admin/delete`,
		method: 'get',
		params:data
	})
}
//获取设备用户列表
export function userlist(data) {
	return request({
		url: `/user/list`,
		method: 'get',
		params:data
	})
}
//禁用、启用
export function Disable(data) {
	return request({
		url: `/admin/edit/userStatus`,
		method: 'get',
		params:data
	})
}