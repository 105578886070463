/**
 * 各种正则表达式
 * email    电子邮箱
 * words   文字
 * special  特殊字符
 * mobile   手机号
 * integer   正整数包含0
 * ip        ip地址
 * url       网址
 * port      端口
 * number    数字
 */
// 电子邮箱
export const email = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
// 限制文字
export const words = /[\u4E00-\u9FA5]/
export const numbers = /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/
//限制特殊字符
export const special = /[`~!@#$%^&*()_\-+=<>?:"{}|,.;'\\[\]/·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/
// 手机号
export const mobile = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
//正整数包含0
export const integer=/^([1-9]\d*|[-1,0]{1,1})$/
//ip地址
export const ip=/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
//网址
export const url=/^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
//端口
export const port=/^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/