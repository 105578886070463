import request from '../utils/request.js'

//获取角色列表
export function list() {
	return request({
		url: `/admin/roles`,
		method: 'get'
	})
}
//获取权限
export function initPrivs() {
	return request({
		url: `/admin/list_priv`,
		method: 'get',
	})
}
//获取角色对应的权限列表
export function initPrivs_a(data) {
	return request({
		url: `/admin/list_role_priv`,
		method: 'get',
		params:data
	})
}
//添加角色
export function addrole(data) {
	return request({
		url: `/admin/add_role?name=${data.name}&desc=${data.desc}&priv_ids=${data.priv_ids}`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//编辑角色
export function edit(data) {
	return request({
		url: `/admin/edit_role`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//修改角色对应的权限列表
export function edit_a(data) {
	return request({
		url: `/admin/edit_role_priv`,
		method: 'GET',
		params:data
	})
}
//删除角色
export function deletes(data) {
	return request({
		url: `/admin/del_role`,
		method: 'GET',
		params:data
	})
}
//获取管理员隶属角色
export function admin(data) {
	return request({
		url: `/admin/admin_roles`,
		method: 'GET',
		params:data
	})
}

//获取工单消息提示
export function getReadStatus() {
	return request({
		url: `/workOrder/getReadStatus`,
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

//解绑
export function devicedelete(mid) {
	return request({
		url: `/device/delete?mid=` + mid,
		method: 'put',
		headers: {
			'Content-Type': 'application/json'
		}
	})
}